<template>
	<div class="teacher">
		<list-template
      :loading="loading"
			:total="total"
			:table-data="tableData"
			:table-config="tableConfig"
			:search-config="searchConfig"
			:current-page="page"
			@onSearch="onSearch"
			@onReset="search = null"
			@onChangePage="changeCurrentPage"
			@onHandle="tableHandle"
			hasAdd
			@onAdd="onAdd"
		></list-template>
		<el-dialog :visible.sync="dialogVisible" width="30%">
			<span>删除后,相关数据将会清除。</span>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">点错了</el-button>
				<el-button type="primary" @click="confirm">删除</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import { deleteExam } from './api'
import { mapState } from 'vuex'
export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  computed: {
		...mapState(['page'])
	},
	data() {
		return {
      loading:true,
			// 表格搜索配置
			searchConfig: [
				{
					prop: 'keyword',
					placeholder: '搜索考试名称'
				}
			],
			// 表格配置
			tableConfig: [
				{
					prop: 'name',
					label: '考试名称'
				},
        {
					prop: 'creator',
					label: '创建人'
				},
				{
					label: '操作',
					handle: true,
					width: 140,
          buttons:(row) => {
            if (this.$store.getters.userInfo.userinfo.id == row.creator_id) {
              return [{ type:"edit", text:"编辑" }, { type:"delete", text:"删除" }]
            }
            return [{ type:"view", text:"查看" }]
          },
				}
			],
			search: null,
			total: 0,
			dialogVisible: false,
			// 表格中的数据
			tableData: [],
			// 删除宿舍的id
			dormitoryId: ''
		}
	},
	mounted() {
		this.$store.commit('setPage', 1)
		this.getData()
	},
	activated() {
		this.getData()
	},
	methods: {
		// 分页被更改
		changeCurrentPage(e) {
			this.$store.commit('setPage', e)
			this.getData()
		},
		/**
		 * 获取数据
		 */
		getData() {
			let { search, page } = this
      this.loading = true;
			this.$_axios.get('exam-count-set/index', { params: { ...search, page } }).then(res => {
				let { data } = res.data
				this.tableData = data
				let { total } = this.$tools.getPaginationInfo(res.headers)
				this.total = total
			}).finally(()=>this.loading = false)
		},
		// 新增按钮
		onAdd() {
			this.$router.push('./add')
		},
		// 搜索按钮被点击
		onSearch(val) {
			this.$store.commit('setPage', 1)
			this.search = val
			this.getData()
		},
		// 表格后面操作被点击
		tableHandle(row, handle) {
      const { type } = handle
			if (type === 'view') {
				this.$router.push({ path: './look', query: { id: row.id } })
			}
			if (type === 'edit') {
				this.$router.push({ path: './edit', query: { id: row.id } })
			}
			if (type === 'delete') {
				this.dialogVisible = true
				this.dormitoryId = row.id
			}
		},
		// 弹窗确定按钮
		confirm() {
			deleteExam(this.dormitoryId).then(res => {

				this.$message.success('删除成功')
				this.dialogVisible = false
				this.getData()
			})
		}
	}
}
</script>

<style scoped lang="scss">
.list {
	padding: 24rem 0 0 32rem;
	.search {
		.el-input,
		.el-select {
			width: 240rem;
			margin-right: 24rem;
		}
	}
	.pagination {
		margin-top: 50rem;
	}
}
</style>
